@import "_mixin";
@import "_loader";

$item0-color: #999;

.SearchSubService {
    a {
        color: #333;
        text-decoration: none;

        &:hover {
            color: #00b0b8;
            text-decoration: underline;
        }
    }

    .item0 {
        color: $item0-color;
    }
}

.HomeService dd {
    .item0 {
        color: $item0-color;
    }
}

.no-result {
    border: 1px solid #00b0b8;
    margin: 20px 0;
    padding: 50px 0;
    background-color: #fff;
    color: #333;
    padding: 50px 0;
    line-height: 3;
    text-align: center;
    font-weight: normal;
}

.SearchSubAreaItem__parent {
    &.type--pref {
        padding-left: 1.7em;
    }

    &.type--city {
        padding-left: 2.4em;
    }

    &.type--town {
        padding-left: 3.1em;
    }
}

.FooterNav {
    .container {
        max-width: 1230px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.Search {
    padding-top: 10px;
}

.breadcrumbs {
    max-width: 1230px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            display: inline;

            &:after {
                content: ' / ';
            }

            &:last-child {
                &:after {
                    content: '';
                }
            }

            span {
                font-size: .8em;
            }

            a {
                color: #666;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.SearchSubService {
    dl {
        dd {
            div {
                padding-left: .5em;
            }
        }
    }
}

.category-top {

    border: 1px solid #00b0b8;
    margin: 20px 0;
    padding: 50px 0;
    background-color: #fff;

    span {
        color: #333;
        padding: 0 0 50px 0;
        line-height: 3;
        text-align: center;
        font-weight: normal;
        display: block;
    }

    .category-pref-list {
        padding: 0 30px;
        margin: 0;
        list-style-type: none;

        li {
            display: inline;

            &:after {
                content: ' / ';
            }

            &:last-child {
                &:after {
                    content: '';
                }
            }

            a {
                color: #333;
            }
        }
    }
}

.keyword-search-area {
    max-width: 1230px;
    margin: 30px auto 50px auto;
    padding: 0 15px;

    section {
        background-color: #fff;
        padding-bottom: 30px;

        .search-results {
            padding-top: 20px;

            .search-result {
                margin-bottom: 20px;
                padding: 0 15px;

                a {
                    text-decoration: none;

                    .title {
                        color: #333;
                        margin-bottom: 5px;
                        font-weight: bold;
                    }

                    .url {
                        color: green;
                        text-decoration: none;
                        font-size: .8em;
                        margin-bottom: 5px;
                    }

                    .snippet {
                        color: #333;
                        text-decoration: none;
                        font-size: .9em;
                    }

                    &:hover {
                        .title {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .load-next {
            background-color: #fff;
            text-align: center;

            a {
                display: inline-block;
                margin: 0;
                border: none;
                padding: .5em 1.2em;
                min-width: 2em;
                background: #ffb400;
                color: #fff;
                border-radius: 5px;
                font-size: .9em;
                cursor: pointer;
                text-decoration: none;
            }
        }
    }

}

.keyword-hit-none {
    padding: 50px 15px;
    text-align: center;

}

@media screen and (max-width: 1000px) {
    .Search__col-main {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .Search__col-main {
        width: 100%;
    }
}

.Static {
    background-color: #f0f0f0;
    padding: 10px 0 34px 0;

    &__inner {
        padding: 0 15px;
        max-width: 1230px;
        margin: auto;

        h1, h3 {
            font-size: 25px;
            margin: 0;
            padding: 15px 0;
            margin-bottom: 20px;
        }

    }
}

.SearchItem__desc {
    a {
        color: #333;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.SmSearchBox {

    .SmSearchBox__body {

        overflow: auto;

        hr {
            margin: 15px 10px 20px 10px;
            color: #ccc;
        }

        h4 {
            padding: 0 10px;
            padding-left: 28px;
            font-weight: normal;
            margin: 10px 10px 20px 10px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 72 72'%3E%3Cg stroke='%23333' fill='none'%3E%3Ccircle stroke-width='9' cx='28' cy='28' r='23'/%3E%3Cpath stroke-width='12' d='M44 44l23 23'/%3E%3C/g%3E%3C/svg%3E");
        }

    }
}

.not-found-cont {
    max-width: 1230px;
    padding: 0 15px;
    margin: auto;

    h3 {
        text-align: center;
        padding: 40px 0;
    }

    .message {
        text-align: center;
        padding-bottom: 50px;
    }

    .back {
        text-align: center;
        padding-bottom: 40px;

        a {
            display: inline-block;
            padding: 10px 30px;
            color: #fff;
            font-weight: normal;
            text-decoration: none;
            background-color: #ffb400;
            border-radius: 4px;
        }
    }
}

.form-wrapper {
    width: 100%;
    background-color: #f0f0f0;
    padding: 10px 15px 50px 15px;

    &_inner {
        max-width: 1030px;
        margin: auto;
        margin-top: 20px;
        padding: 0 15px 20px 15px;
        background-color: #fff;
    }

    h1 {
        background-color: #00b0b8;
        color: #fff;
        margin: 0 -15px;
        font-size: 20px;
        padding: .4em 1em;
        margin-bottom: 20px;

    }

    p {
        font-size: .9em;
        max-width: 900px;
        margin: auto;
        margin-bottom: 25px;
    }

    &_parts {
        max-width: 900px;
        margin: auto;
        margin-bottom: 5px;
        display: flex;
        border-bottom: 1px solid #ddd;

        label {
            align-self: center;
            display: block;
            width: 20%;
            margin-right: 10px;
            padding: 10px 20px;
        }

        & > div {
            align-self: center;
            flex: 1;
            padding: 10px 0;

            input, textarea {
                display: block;
                width: 100%;
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 4px;
                font-size: inherit;
                font-family: inherit;
            }

            textarea {
                height: 300px;
            }
        }

        .error-message {
            color: #cc0000;
            font-size: .8em;

        }
    }

    &_buttons {
        margin-top: 20px;
        text-align: center;

        button, .btn {
            margin: 0;
            border: none;
            padding: .5em 1.2em;
            min-width: 2em;
            height: 40px;
            color: #fff;
            border-radius: 5px;
            font-size: 20px;
            cursor: pointer;
            text-decoration: none;
            line-height: 1;

            &.btn1 {
                background: #ffb400;
            }

            &.btn2 {
                background: #ccc;
            }

            &:nth-child(2) {
                margin-left: 25px;
            }
        }
    }

    @include mobile() {
        &_parts {
            display: block;
            padding-bottom: 10px;

            label {
                display: block;
                width: 100%;
                margin-right: 0;
                padding: 10px 0 0 0;
            }

            input, textarea {
            }

            textarea {
                height: 300px;
            }
        }
        &_buttons {
            button, .btn {
                display: block;
                width: 100%;
                margin-bottom: 20px;

                &:nth-child(2) {
                    margin-left: 0;
                }
            }
        }

    }
}

.alert-danger {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid #f5c6cb;
    border-radius: .25rem;
    color: #721c24;
    background-color: #f8d7da;

    &.alert-dismissible .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: .75rem 1.25rem;
        color: inherit;
        border-style: none;
        font-size: 24px;
        background-color: transparent;
    }
}

.footer-ad {
    padding: 0 15px 50px 15px;

    &.lower {
        background-color: #f0f0f0;
    }

    a {
        max-width: 1200px;
        margin: auto;
        border: 1px solid #ddd;
        padding: 10px;
        display: block;
        color: #333;
        text-decoration: none;
        font-size: 15px;
        background-color: #fff;

        &:hover {
            text-decoration: underline;
        }

        label {
            display: inline-block;
            line-height: 1.7;
            padding: 0 10px;
            margin-right: 10px;
            background-color: #eee;
            border-radius: 4px;
        }

        span.domain {
            display: inline-block;
            color: #00b0b8;
            margin-left: 10px;
        }
    }

    @include mobile() {
        a {
            max-width: 450px;
            padding: 10px 10px;

            display: flex;
            font-size: 13px;
            flex-wrap: wrap;

            label {
                order: 1;
                margin-bottom: 5px;
            }

            span.text {
                order: 3;
            }

            span.domain {
                order: 2;
                margin-left: 0;
                margin-bottom: 5px;
            }

        }
    }
}

main {
    background-color: #f0f0f0;

    &.main-top {
        background-color: #fff;
    }
}

.SearchBottomNav {
    background-color: #fff;
    margin-bottom: 50px;
}
