#spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .1);

    .loader {
        $wh: 50px;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -$wh/2;
        margin-left: -$wh/2;
        width: $wh;
        height: $wh;
        animation: spin 1s linear infinite;
        img {
            width: 100%;
            height: 100%;
            opacity: .8;
        }
    }

    @-webkit-keyframes spin {
        0% {-webkit-transform: rotate(0deg);}
        100% {-webkit-transform: rotate(360deg);}
    }
    @-moz-keyframes spin {
        0% {-moz-transform: rotate(0deg);}
        100% {-moz-transform: rotate(360deg);}
    }
    @-ms-keyframes spin {
        0% {-ms-transform: rotate(0deg);}
        100% {-ms-transform: rotate(360deg);}
    }
    @-o-keyframes spin {
        0% {-o-transform: rotate(0deg);}
        100% {-o-transform: rotate(360deg);}
    }
    @keyframes spin {
        0% {transform: rotate(0deg);}
        100% {transform: rotate(360deg);}
    }
}
