// -------------------------------------- 変数

$desktop-max-width: 1200px;


// -------------------------------------- メディアクエリ
@mixin mobile() {
    @media screen and (max-width:767px) {
        @content;
    }
}
@mixin desktop() {
    @media screen and (min-width: 768px) {
        @content;
    }
}
